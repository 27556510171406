const Stack = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4472 3.60557C12.1657 3.46481 11.8343 3.46481 11.5528 3.60557L1.55279 8.60557C1.214 8.77496 1 9.12123 1 9.5C1 9.87877 1.214 10.225 1.55279 10.3944L4.76393 12L1.55279 13.6056C1.214 13.775 1 14.1212 1 14.5C1 14.8788 1.214 15.225 1.55279 15.3944L11.5528 20.3944C11.8343 20.5352 12.1657 20.5352 12.4472 20.3944L22.4472 15.3944C22.786 15.225 23 14.8788 23 14.5C23 14.1212 22.786 13.775 22.4472 13.6056L19.2361 12L22.4472 10.3944C22.786 10.225 23 9.87877 23 9.5C23 9.12123 22.786 8.77496 22.4472 8.60557L12.4472 3.60557ZM16.5618 11.1011C16.5553 11.1042 16.5488 11.1075 16.5424 11.1108L12 13.382L7.45761 11.1108C7.45117 11.1075 7.4447 11.1042 7.43819 11.1011L4.23607 9.5L12 5.61803L19.7639 9.5L16.5618 11.1011ZM7 13.118L11.5528 15.3944C11.8343 15.5352 12.1657 15.5352 12.4472 15.3944L17 13.118L19.7639 14.5L12 18.382L4.23607 14.5L7 13.118Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Stack;
