import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import api from '../api';

export const PROFILE_QUERY_KEY = 'profile';

const useProfile = () => {
  const { data: session } = useSession();
  return useQuery({
    queryKey: [PROFILE_QUERY_KEY],
    queryFn: async () => {
      const res = await api.profiles.getCurrentProfile();

      const data = res.data?.data;
      const dataAttributes = data?.attributes;

      const profileName =
        dataAttributes?.legal_company_name || dataAttributes?.name;

      const hasIndustry = dataAttributes?.industries?.data?.length;

      const businessRequirement =
        hasIndustry &&
        profileName &&
        dataAttributes?.overview &&
        dataAttributes?.brand_tone_and_voice;
      const individualRequirement =
        profileName &&
        dataAttributes?.overview &&
        dataAttributes?.brand_tone_and_voice;

      const hasCompletedProfile =
        dataAttributes?.company_type !== 'personal'
          ? Boolean(businessRequirement)
          : Boolean(individualRequirement);

      return {
        id: data?.id,
        ...dataAttributes,
        logo: {
          id: dataAttributes?.logo?.data?.id,
          ...dataAttributes?.logo?.data?.attributes,
        },
        general_assets: dataAttributes?.general_assets?.data?.map((val) => ({
          id: val.id,
          ...val?.attributes,
        })),
        industries: dataAttributes?.industries?.data?.map((val) => ({
          id: val.id,
          ...val?.attributes,
        })),
        has_completed_profile: hasCompletedProfile,
      };
    },
    enabled: !!session,
  });
};

export default useProfile;
