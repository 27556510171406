import React from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(prev => !prev);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return { open, close, toggle, isOpen, setIsOpen };
};

export default useModal;
